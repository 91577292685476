.pageTitle {
    margin-bottom: 0px;
    font-family: 'Linotype avenir next lt pro demi', sans-serif;
    color: #111;
    font-size: 30px;
    line-height: 52px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
}

.pageSubtitle {
    margin-bottom: 30px;
    padding-right: 20px;
    padding-left: 20px;
    font-family: 'Linotype avenir next lt pro', sans-serif;
    color: #777;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
}

@media (max-width: 991px) {
    .pageTitle {
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 48px;
    }
}

@media (max-width: 767px) {
    .pageTitle {
        font-size: 24px;
        line-height: 48px;
    }
    .pageSubtitle {
        font-size: 16px;
    }
}

@media (max-width: 479px) {
    .pageTitle {
        padding-right: 0px;
        padding-left: 0px;
        font-size: 22px;
        line-height: 36px;
    }
}
