.paragraph {
    display: block;
    margin-bottom: 20px;
    margin-left: 0px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #5d5d5d;
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
    text-align: left;
}

.heading {
    position: static;
    display: inline-block;
    margin-bottom: 5px;
    padding-top: 0px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #32335c;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    text-align: left;
}

.container {
    display: inline-block;
    width: 100%;
    padding: 0 10px;
    text-align: left;
}

.container a {
    color: #999;
}