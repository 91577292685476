.paragraph {
    display: block;
    margin-bottom: 20px;
    margin-left: 0px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #5d5d5d;
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    text-align: left;
}

.heading {
    position: static;
    display: inline-block;
    margin-bottom: 10px;
    padding-top: 0px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #32335c;
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    text-align: left;
}

.container {
    display: inline-block;
    width: 95%;
    float: right;
    text-align: left;
}

.buffer_top {
    margin-top: 30px;
}

.disclaimer {
    margin-top: 10px;
    font-family: 'Linotype avenir next lt pro demi', sans-serif;
    font-size: 10px;
    color: #999;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
}

/** Styles for the table header **/

.heading_flexbox_row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: center;
    justify-content: center;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #efefef;
    font-family: 'Linotype avenir next lt pro demi', sans-serif;
    font-size: 16px;
    letter-spacing: -0.2px;
    height: 60px;
}

.heading_cell_traditional {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px none #eceef7;
    border-left: 1px none #eceef7;
}