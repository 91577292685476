.subheading {
    font-family: 'Linotype avenir next lt pro demi', sans-serif;
    font-size: 20px;
    text-align: left;
}

.divider {
    height: 60px;
}


