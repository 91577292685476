.section_footer_v2 {
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid #dde2ee;
    background-color: #fff;
    text-align: center;
}

.container_1200_max {
    display: inline-block;
    overflow: visible;
    width: 100%;
    max-width: 1240px;
    min-height: auto;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-left: 20px;
    border: 1px none #000;
    background-color: transparent;
    text-align: center;
}

.container_1200_max_testimonials {
    display: inline-block;
    overflow: visible;
    width: 100%;
    max-width: 1240px;
    min-height: auto;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 20px;
    padding-bottom: 0px;
    padding-left: 20px;
    border: 1px none #000;
    background-color: transparent;
    text-align: center;
}

.logo_footer {
    display: inline-block;
    max-width: none;
    margin-top: 4px;
    float: none;
    text-align: center;
}

.footer_paragraph {
    display: block;
    margin-bottom: 20px;
    margin-left: 0px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #a8a8a8;
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    text-align: center;
}


@media (max-width: 991px) {
    .section_footer_v2 {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .container_1200_max {
        max-width: none;
        margin-right: 0px;
        margin-left: 0px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .container_1200_max_testimonials {
        max-width: none;
        margin-right: 0px;
        margin-left: 0px;
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media (max-width: 479px) {
    .container_1200_max {
        display: block;
        float: none;
        text-align: center;
    }

    .footer_paragraph {
        margin-left: 0px;
    }

    .logo_footer {
        display: inline-block;
        float: none;
        text-align: center;
    }
}