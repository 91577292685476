.flexbox_row_standard {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    border-style: none;
    border-width: 1px;
    border-color: #eee;
    border-radius: 5px;
}

.flexbox_standard_left {
    width: 46%;
    border-right: 1px none #000;
    border-left: 1px solid #dbdbdb;
}
  
.flexbox_standard_left.bottom_border_left {
    border-bottom: 1px solid #dbdbdb;
    border-bottom-left-radius: 4px;
}

.deposit_table_text {
  margin-bottom: 10px;
  padding-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
  font-family: 'Linotype avenir next lt pro', sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.deposit_table_text.align_left {
  margin-bottom: 0px;
  padding-right: 5px;
  padding-bottom: 10px;
  text-align: left;
  letter-spacing: -0.2px;
}

.deposit_table_text_bold {
  margin-bottom: 10px;
  padding-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
  font-family: 'Linotype avenir next lt pro', sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.deposit_table_text_bold.align_left {
  margin-bottom: 0px;
  padding-right: 5px;
  padding-bottom: 10px;
  font-family: 'Linotype avenir next lt pro demi', sans-serif;
  text-align: left;
  letter-spacing: -0.2px;
}

.deposit_table_text_bold_sum {
  margin-bottom: 10px;
  padding-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
  font-family: 'Linotype avenir next lt pro demi', sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.deposit_table_text_bold_sum.align_left {
  padding-right: 5px;
  text-align: left;
  letter-spacing: -0.2px;
}

.flexbox_standard_middle {
  width: 27%;
  border-left: 1px solid #dbdbdb;
}

.flexbox_standard_middle.bottom_border_middle {
  border-bottom: 1px solid #dbdbdb;
}

.flexbox_standard_right {
  width: 27%;
  border-right: 1px solid #75d8b4;
  border-left: 1px solid #75d8b4;
  background-color: rgba(0, 202, 127, 0.08);
}

.flexbox_standard_right.bottom_border_right {
  border-bottom: 1px solid #75d8b4;
  border-right-color: #75d8b4;
  border-left-color: #75d8b4;
  border-bottom-right-radius: 4px;
}

.text_span {
  color: #999;
}

.deposit_table_percent_savings {
  margin-top: -10px;
  margin-bottom: 10px;
  padding-top: 0px;
  font-family: 'Linotype avenir next lt pro', sans-serif;
  color: #25b47e;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
}

.deposit_table_percent_savings.align_left {
  padding-right: 5px;
  text-align: left;
  letter-spacing: -0.2px;
}

@media (max-width: 479px) {
    .flexbox_row_standard {
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }

    .deposit_table_text.align_left {
        padding-left: 10px;
        line-height: 22px;
    }

    .deposit_table_text_bold.align_left {
        line-height: 22px;
    }
    .deposit_table_text_bold_sum {
        line-height: 22px;
    }
    .deposit_table_percent_savings {
        margin-top: -5px;
        line-height: 22px;
    }
}