.page_section {
    overflow: hidden;
    margin-top: 0px;
    padding-top: 40px;
    padding-bottom: 100px;
    text-align: center;
}

.page_content {
    display: inline-block;
    overflow: visible;
    width: 100%;
    max-width: 600px;
    min-height: auto;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    border: 1px none #000;
    background-color: transparent;
    text-align: center;
}

@media (max-width: 991px) {
    .page_section {
        margin-top: 0px;
        padding-top: 40px;
    }

    .page_content {
        max-width: 600px;
        margin-right: 0px;
        margin-left: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

}

@media (max-width: 767px) {
    .page_section {
        margin-top: 0px;
        padding-top: 30px;
    }

    .page_content {
        padding-right: 20px;
        padding-left: 20px;
    }
}

@media (max-width: 479px) {
    .page_section {
        margin-top: 0px;
        padding-top: 20px;
        padding-bottom: 40px;
    }

    .page_content {
        display: block;
        max-width: none;
        padding-right: 10px;
        padding-left: 10px;
        float: none;
        text-align: center;
    }
}