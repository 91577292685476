.callout_flexbox_row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.empty_cell {
    width: 73%;
}

.call_out_box {
    width: 27%;
    min-height: 20px;
    min-width: auto;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #46ce90;
}

.text_block_4 {
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: 'Linotype avenir next lt pro demi', sans-serif;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.2px;
    text-align: center;
}

.heading_flexbox_row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #efefef;
}
.empty_heading_left {
    width: 64%;
}

.empty_heading_left.flex_size_left {
    width: 46%;
}

.heading_radio_cell_traditional {
    width: 27%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px none #eceef7;
    border-left: 1px solid #dbdbdb;
}

.test_radio_div {
    display: inline-block;
    overflow: visible;
    width: 100%;
    margin-bottom: 0px;
    padding-bottom: 0px;
    text-align: center;
}

.test_radio {
    width: 100%;
    margin-bottom: 0px;
}

.w_radio {
	display: block;
	margin-bottom: 5px;
	padding-left: 20px
}

.w_radio:before,
.w_radio:after {
	content: " ";
	display: table;
	grid-column-start: 1;
	grid-row-start: 1;
	grid-column-end: 2;
	grid-row-end: 2
}

.w_radio:after {
	clear: both
}

.w_radio_input {
	margin: 4px 0 0;
	margin-top: 1px \9;
	line-height: normal;
	float: left;
	margin-left: -20px
}

.w_radio_input {
	margin-top: 3px
}

.displaynone {
    display: none;
}

.test_radio_label_traditional {
    position: relative;
    z-index: 1000;
    display: inline-block;
    width: 100%;
    min-height: 80px;
    min-width: 100%;
    margin-bottom: 0px;
    margin-left: -20px;
    padding-right: 0px;
    padding-left: 0px;
    font-family: 'Linotype avenir next lt pro demi', sans-serif;
    font-size: 16px;
    letter-spacing: -0.2px;
}

.w_form_label {
	display: inline-block;
	cursor: pointer;
	font-weight: normal;
	margin-bottom: 0;
}

.radio_div_holder {
    position: relative;
    display: block;
    overflow: visible;
    max-height: none;
    /* margin-top: -30px; */
    /* margin-left: 22px; */
    padding-top: 0px;
    text-align: center;
    width: 100%;
}

.heading_radio_cell_rentline {
    width: 27%;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid #75d8b4;
    border-left: 1px solid #75d8b4;
}

.test_radio_rentline {
    position: relative;
    z-index: 1001;
    display: inline-block;
    width: 100%;
    min-height: 80px;
    margin-bottom: 0px;
    margin-left: -20px;
    padding-right: 0px;
    padding-left: 0px;
    font-family: 'Linotype avenir next lt pro demi', sans-serif;
    letter-spacing: -0.2px;
}

@media (max-width: 479px) {
    .heading_flexbox_row {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .heading_radio_cell_traditional {
        padding-bottom: 10px;
    }

    .test_radio_div {
        width: 100%;
        padding-bottom: 0px;
        text-align: center;
    }

    .test_radio {
        overflow: visible;
        width: auto;
    }

    .test_radio_label_traditional {
        position: relative;
        display: inline-block;
        min-height: 80px;
        min-width: 100%;
        margin-bottom: 0px;
        line-height: 20px;
        font-weight: 600;
        text-align: center;
    }

    .radio_div_holder {
        display: block;
        overflow: visible;
        height: auto;
        max-height: none;
        min-height: auto;
        margin-top: -30px;
        text-align: center;
    }

    .heading_radio_cell_rentline {
        overflow: visible;
        padding-bottom: 10px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
    }

    .test_radio_rentline {
        position: relative;
        display: inline-block;
        min-height: 80px;
        line-height: 20px;
    }
}