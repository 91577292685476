.headerSection {
    position: static;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 99999;
    display: inline-block;
    width: 100%;
    height: 70px;
    min-height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    border-style: none none solid;
    border-width: 1px;
    border-color: #000 #000 #dde2ee;
    background-color: #3b295d;
    color: #3b295d;
    text-align: center;
}

.headerContainer {
    display: inline-block;
    overflow: visible;
    width: 600px;
    max-width: 600px;
    min-height: auto;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    border: 1px none #000;
    background-color: transparent;
    text-align: center;
}

.headerPropertyContainer {
    display: inline-block;
    width: 60%;
}

.propertyName {
    font-family: 'Linotype avenir next lt pro', sans-serif;
    color: #fff;
    font-size: 24px;
    text-align: left;
    padding-top: 0;
}

.poweredByText {
    display: none;
    font-family: 'Linotype avenir next lt pro', sans-serif;
    color: #fff;
    font-size: 24px;
    text-align: left;
}

.headerLogoContainer {
    display: inline-block;
    width: 40%;
    float: right;
    text-align: right;
}

.logo {
    display: none;
}

.wclearfix:before, .wclearfix:after {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
}

.wclearfix:after {
	clear: both
}

.poweredbylogo {
    height: 34px;
}

@media (max-width: 991px) {
    .headerSection {
        position: static;
        width: 100%;
    }

    .headerContainer {
        max-width: none;
        margin-right: 0px;
        margin-left: 0px;
        padding-right: 0px;
        padding-left: 0px;
    }

    .poweredbylogo {
        height: 34px;
    }
}

@media (max-width: 767px) {
    .headerSection {
        position: static;
        display: inline-block;
        width: 100%;
        height: auto;
        padding-top: 12px;
        padding-bottom: 10px;
    }

    .headerContainer {
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
    }

    .headerPropertyContainer {
        width: 80%;
        float: left;
    }

    .propertyName {
        margin-bottom: 0px;
        font-size: 22px;
        line-height: 26px;
    }

    .poweredByText {
        display: block;
        margin-bottom: 0px;
        opacity: 0.8;
        font-size: 14px;
        line-height: 22px;
    }

    .headerLogoContainer {
        width: 20%;
        padding-top: 2px;
        float: right;
        text-align: right;
    }

    .logo {
        display: inline-block;
    }

    .poweredbylogo {
        display: none;
    }
}

@media (max-width: 479px) {
    .headerSection {
        position: static;
        display: inline-block;
        width: 100%;
        height: auto;
        padding-top: 12px;
        padding-bottom: 10px;
        background-color: #3b295d;
    }

    .headerContainer {
        display: block;
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        float: none;
        text-align: center;
    }

    .headerPropertyContainer {
        display: inline-block;
        width: 80%;
        float: left;
    }

    .propertyName {
        margin-bottom: 0px;
        font-family: 'Linotype avenir next lt pro', sans-serif;
        color: #fff;
        font-size: 22px;
        line-height: 26px;
        text-align: left;
    }

    .poweredByText {
        margin-bottom: 0px;
        opacity: 0.8;
        font-family: 'Linotype avenir next lt pro', sans-serif;
        color: #fff;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
    }

    .headerLogoContainer {
        width: 20%;
        padding-top: 2px;
        float: right;
        text-align: right;
    }

    .logo {
        height: 40px;
        max-width: none;
        margin-top: 0px;
        padding-top: 0px;
        text-align: center;
        display: inline-block;
    }

    .poweredbylogo {
        height: 40px;
        max-width: none;
        margin-top: 0px;
        padding-top: 0px;
        text-align: center;
    }
}