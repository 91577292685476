.primary {
    display: inline-block;
    width: 60%;
    height: 60px;
    margin-top: 20px;
    padding: 14px 20px 13px;
    border-style: none;
    border-width: 1px;
    border-color: #46ce90;
    border-radius: 6px;
    background-color: #253171;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    font-family: 'Linotype avenir next lt pro demi', sans-serif;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0px;
}

.mini {
    font-family: 'Linotype avenir next lt pro', sans-serif;
    color: #253171;
    display: inline-block;
    border: 1px solid #253171;
    box-sizing: border-box;
    border-radius: 99px;
    font-size: 14px;
    background-color: white;
    display: flex;
    padding-right: 20px;
}

.buttonText {
    margin: auto;
}

.add {
    padding: 0px;
    margin: auto;
}

.addIcon {
    margin: auto;
    width: 3em;
    height: 3em;
    fill: #253171;
}

@media (max-width: 991px) {
    .primary {
        margin-top: 30px;
    }
}

@media (max-width: 767px) {
    .primary {
        width: 100%;
        margin-top: 20px;
        text-align: center;
    }
}

@media (max-width: 479px) {
    .primary {
        display: block;
        margin-top: 20px;
        background-color: #3b295d;
        text-align: center;
    }
}
