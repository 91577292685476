.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;    
    font-family: 'Linotype avenir next lt pro', sans-serif;
    font-size: 16px;
    color: #999;
    text-align: left;
    widows: 85%;
}

.container a {
    color: #999;
}
  
/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    border-color: #3b295d;
    border-width: 4px;
    border-style: solid;
    background-color: white;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: #3b295d;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 3px;
    top: -2px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.wrapper {
    margin: 10px;
}