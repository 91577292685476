@font-face {
    font-family: 'Linotype avenir next lt pro demi';
    src: url('./fonts/Linotype - Avenir Next LT Pro Demi.ttf') format('truetype');
}

@font-face {
    font-family: 'Linotype avenir next lt pro';
    src: url('./fonts/Linotype - Avenir Next LT Pro.ttf') format('truetype');
}

html {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding:0;
}

*, *:before, *:after {
    box-sizing: inherit;
}

h1 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: Roboto, sans-serif;
    font-size: 35px;
    line-height: 40px;
    font-weight: 500;
  }
  
h2 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: Roboto, sans-serif;
    font-size: 32px;
    line-height: 36px;
    font-weight: 500;
}
  
h3 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: Roboto, sans-serif;
    font-size: 26px;
    line-height: 33px;
    font-weight: 500;
}
  
h4 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    line-height: 27px;
    font-weight: 500;
}
  
h5 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
}
  
h6 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
}
  
p {
    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 22px;
    font-weight: 400;
}  